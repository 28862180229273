import '@plone/volto/config';

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,
    defaultPageSize: 100,
    isMultilingual: true,
    supportedLanguages: ['fi'],
    defaultLanguage: 'fi',
  };
  return config;
}
