/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import voltoCookieConsentAddon from 'volto-cookie-consent-addon';
import voltoSubblocks from 'volto-subblocks';
import voltoFormBlock from 'volto-form-block';
import eeacmsvoltoColumnsBlock from '@eeacms/volto-columns-block';

const addonsInfo = [{"name":"volto-cookie-consent-addon","version":"1.1.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/nix/store/5q9qdakd6vzdc4w0av4fzlfbkmy174hb-aihub-frontend/lib/aihub-frontend/node_modules/volto-cookie-consent-addon/src","packageJson":"/nix/store/5q9qdakd6vzdc4w0av4fzlfbkmy174hb-aihub-frontend/lib/aihub-frontend/node_modules/volto-cookie-consent-addon/package.json","addons":[]},{"name":"volto-subblocks","version":"1.0.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/nix/store/5q9qdakd6vzdc4w0av4fzlfbkmy174hb-aihub-frontend/lib/aihub-frontend/node_modules/volto-subblocks/src","packageJson":"/nix/store/5q9qdakd6vzdc4w0av4fzlfbkmy174hb-aihub-frontend/lib/aihub-frontend/node_modules/volto-subblocks/package.json","addons":[]},{"name":"volto-form-block","version":"2.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/nix/store/5q9qdakd6vzdc4w0av4fzlfbkmy174hb-aihub-frontend/lib/aihub-frontend/node_modules/volto-form-block/src","packageJson":"/nix/store/5q9qdakd6vzdc4w0av4fzlfbkmy174hb-aihub-frontend/lib/aihub-frontend/node_modules/volto-form-block/package.json","addons":["volto-subblocks"]},{"name":"@eeacms/volto-columns-block","version":"4.5.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/nix/store/5q9qdakd6vzdc4w0av4fzlfbkmy174hb-aihub-frontend/lib/aihub-frontend/node_modules/@eeacms/volto-columns-block/src","packageJson":"/nix/store/5q9qdakd6vzdc4w0av4fzlfbkmy174hb-aihub-frontend/lib/aihub-frontend/node_modules/@eeacms/volto-columns-block/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [voltoCookieConsentAddon, voltoSubblocks, voltoFormBlock, eeacmsvoltoColumnsBlock];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
